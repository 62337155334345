<template>
  <div class="footer-wrap">
    <footer id="footer" class="section section--footer">
      <div class="section__wrapper">
        <div class="footer">
          <h2 class="text-44 text-bold footer__item footer__item-heading">
            Контакты
          </h2>
          <div class="footer__item footer__item-socials">
            <ul class="footer__social-list">
              <li
                v-for="social in socialsData"
                :key="social?.id"
                class="footer__social-item social"
              >
                <NuxtLink
                  :to="social?.link"
                  class="social__item"
                  target="_blank"
                >
                  <img
                    :src="
                      useStrapiMediaUrl(social?.icon?.data?.attributes?.url)
                    "
                    :width="social?.icon?.data?.attributes?.width"
                    :height="social?.icon?.data?.attributes?.height"
                    class="social__logo"
                    loading="lazy"
                  />
                </NuxtLink>
              </li>
              <Share />
            </ul>
          </div>
          <div class="footer__item footer__item-contacts">
            <ul class="footer__contacts-list">
              <li class="footer__contact-item">
                <h3 class="footer__subheading text-16 text-bold">
                  Возникли вопросы?
                </h3>
                <a href="mailto: edtech@kp.ru" class="text-16">edtech@kp.ru</a>
              </li>
              <li class="footer__contact-item">
                <h3 class="footer__subheading text-16 text-bold">Адрес</h3>
                <p class="text-16">
                  12332, Москва<br />
                  Новодмитровская 2Б
                </p>
              </li>
              <li class="footer__contact-item">
                <h3 class="footer__subheading text-16 text-bold">Телефон</h3>
                <a href="tel:+74957770282" class="text-16">
                  + 7 (495) 777-02-82
                </a>
              </li>
            </ul>
          </div>
          <div class="footer__item footer__item-links">
            <h3 class="footer__subheading text-16 text-bold">Номинации</h3>
            <ClientOnly>
              <ul v-if="nominations" class="footer__links-list">
                <li
                  v-for="(nomination, id) of nominations"
                  :key="id"
                  class="footer__links-item"
                >
                  <NuxtLink :to="getNominationLink(nomination.attributes.slug)">
                    {{ nomination.attributes.shortTitle }}
                  </NuxtLink>
                </li>
              </ul>
            </ClientOnly>
          </div>
          <div class="footer__item-full footer__item-bottom">
            <NuxtImg
              src="/img/logo_footer.svg"
              class="footer__bottom-item footer__logo"
              loading="lazy"
            />
            <ul class="footer__bottom-item footer__copyright">
              <li>
                <NuxtLink
                  :to="{ name: 'oferta' }"
                  class="text-16 text-underline"
                >
                  Положение
                </NuxtLink>
              </li>
              <li>Комсомольская правда © 2024</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
const globalData = await getGlobals()

const socialsData = computed(() => {
  return globalData?.attributes.socials
})
const nominations = await getNominations()
</script>

<style lang="scss">
.section {
  &--footer {
    padding: 70px 70px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: $color-light-gray;

    @include md {
      padding: 36px;
    }
    @include sm {
      padding: 20px 16px;
    }
    &::before,
    &::after {
      content: "";
      z-index: -1;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 620px;
      aspect-ratio: 1;
      border-radius: 50%;
      filter: blur(400px);

      @include sm {
        filter: blur(250px);
        width: 320px;
      }
    }
    &::before {
      background-color: #0055fa;
      transform: translateZ(0) translateX(120%) scale(0.8);
    }
    &::after {
      background-color: #5738cf;
      transform: translateZ(0) translate(50%, 35%);
    }
  }
}
.footer {
  --items-gap: 40px;
  --rows-gap: 60px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--items-gap);
  @include md {
    --items-gap: 60px;
  }
  @include sm {
    --items-gap: 30px;
    flex-direction: column;
    font-size: 12px;

    .text-16:not(h3) {
      font-size: 12px;
    }
  }
  &__logo {
    max-width: 640px;
    width: 100%;
    display: block;

    @include md {
      max-width: 672px;
      width: 100%;
    }
    @include sm {
      width: 100%;
    }
  }
  &__item {
    width: calc(50% - var(--items-gap) / 2);

    @include sm {
      width: 100%;

      &-heading {
        margin-bottom: var(--rows-gap);
      }
    }
    &-full {
      width: 100%;
    }
    &-links {
      .footer__subheading {
        margin-bottom: 2em;
      }
    }
    &-socials {
      display: flex;
      justify-content: flex-end;

      @include sm {
        justify-content: flex-start;
        order: 4;
        margin-top: var(--rows-gap);
      }
    }
    &-bottom {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      gap: var(--items-gap);
      margin-top: var(--rows-gap);

      @include md {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
      }
      @include sm {
        order: 5;
      }
    }
  }
  &__links-list {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: 10px;
  }
  &__links-item {
    font-size: 12px;

    .router-link-active {
      font-weight: 700;
      color: $color-purple;
    }
  }
  &__logo {
    display: block;
    object-fit: cover;
  }
  &__copyright {
    li {
      margin: 0 0 12px 0;
      a {
        &:hover {
          opacity: 0.7;
        }
      }
      @include md {
        margin: 0 0 10px 0;
      }
      &:last-child {
        margin: 0 0 0 0;
      }
    }
  }
  &__contacts-list {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: var(--items-gap);
  }
  &__contact-item {
    max-width: 203px;
    width: 100%;
    a {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &__social-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 6px;

    .share-container {
      margin-top: unset;
    }
  }
  &__subheading {
    margin: 0 0 12px 0;
    @include md {
      margin: 0 0 10px 0;
    }
  }
}
.social {
  &__item {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-purple;
    border-radius: 50%;
    transition: 0.5s;
    &:hover {
      background-color: #504cd6;
    }
  }
}
.footer-wrap {
  position: relative;
  z-index: 8;
}
</style>
